/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import FormConfigurationAction from '../FormConfiguration/FormConfigurationAction';
import FormConfigurationForm from '../FormConfiguration/FormConfigurationForm';
import PublishFormModal from '../Modal/PublishFormModal';
import { Form } from '../../../../components/form/form';
import { formConfigSchema } from '../../config/forms/validation';
import { getFormDetailById, publishFormById } from '../../../../redux/features/forms/formSlice';

export default function FormDetailConfiguration({ title }) {
  const [isPublishFormModalOpen, setIsPublishFormModalOpen] = React.useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();
  const { form: formDetail } = useSelector((state) => state.form);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(formConfigSchema),
    defaultValues: {
      description: '',
      document_reference: '',
      category: '',
      password: '',
      form_type: formDetail?.form_type,
    },
  });

  useEffect(() => {
    form.reset({
      form_type: formDetail?.form_type,
      description: formDetail?.description,
      document_reference: formDetail?.document_reference,
      password: formDetail?.password,
      category: formDetail?.category?.name,
      is_due_date: formDetail?.is_due_date,
    });
  }, [formDetail]);

  const { isFormPublishLoading } = useSelector((state) => state.form);

  const onPublish = async (values) => {
    try {
      const result = await dispatch(
        publishFormById({
          title,
          description: values.description,
          document_reference: values.document_reference,
          password: values.password,
          category: {
            name: values.category,
          },
          is_approved: true,
          id,
          is_due_date: values.is_due_date,
        }),
      );
      unwrapResult(result);
      toast.success('Form published successfully');
      dispatch(getFormDetailById(id));
      setIsPublishFormModalOpen(false);
    } catch (error) {
      toast.error('Error publishing form');
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col gap-4">
        <FormConfigurationForm />

        <FormConfigurationAction onClickPublish={() => setIsPublishFormModalOpen(true)} />
      </form>

      <PublishFormModal
        open={isPublishFormModalOpen}
        onClose={() => setIsPublishFormModalOpen(false)}
        onPublish={form.handleSubmit(onPublish)}
        isLoading={isFormPublishLoading}
      />
    </Form>
  );
}
