import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const createForm = async (form) => {
  const res = await http.post(`${BASE_URL}/form-requests-add/`, form);

  return assert(res, res.data, 'Creation of form failed', res);
};

const getFormList = async (params) => {
  const queryParams = new URLSearchParams(params).toString();
  const res = await http.get(`${BASE_URL}/form-request-filter?${queryParams}`);

  return assert(res, res.data, 'Retrieval of form list failed', res);
};

const getFormCategories = async () => {
  const res = await http.get(`${BASE_URL}/form-categories`);

  return assert(res, res.data, 'Retrieval of form category list failed', res);
};

const getFormTotal = async () => {
  const res = await http.get(`${BASE_URL}/form-request-filter`);

  return assert(res, res.data, 'Retrieval of form total failed', res);
};

const getFormDetailById = async (formId) => {
  const res = await http.get(`${BASE_URL}/form-requests/${formId}/`);
  return assert(res, res.data, 'Retrieval of form detail by its ID failed', res);
};

const updateFormById = async (form) => {
  const res = await http.put(`${BASE_URL}/form-requests/${form.id}/`, form);

  return assert(res, res.data, 'Update of form failed', res);
};

const duplicateFormById = async (formId) => {
  const res = await http.post(`${BASE_URL}/form-requests/${formId}/`);

  return assert(res, res.data, 'Duplicate of form failed', res);
};

const deleteFormById = async (formId) => {
  const res = await http.delete(`${BASE_URL}/form-requests/${formId}/`);

  return assert(res, formId, 'Deletion of form failed', res);
};

const bulkDeleteFormByIds = async (data) => {
  const params = data.formIds.join(',');
  const res = await http.delete(`${BASE_URL}/form-requests-bulk-delete?ids=${params}`);

  return assert(res, res.data, 'Bulk Delete of form failed', res);
};

const publishFormById = async ({ id, ...data }) => {
  const res = await http.patch(`${BASE_URL}/form-requests-publish/${id}/`, data);

  return assert(res, res.data, 'publish form failed', res);
};

const validateForm = async ({ id, ...data }) => {
  const res = await http.post(`${BASE_URL}/form-validate/${id}/`, data);

  return assert(res, res.data, 'form validate failed', res);
};

const getFormResponseList = async () => {
  const res = await http.get(`${BASE_URL}/form-response/`);

  return assert(res, res.data, 'get form response list failed', res);
};

const createFormResponse = async ({ ...data }) => {
  const res = await http.post(`${BASE_URL}/form-response/`, data);

  return assert(res, res.data, 'create form response failed', res);
};

const updateFormResponse = async ({ ...data }) => {
  const res = await http.patch(`${BASE_URL}/form-response/`, data);

  return assert(res, res.data, 'update form response failed', res);
};

const deleteFormResponse = async ({ ...data }) => {
  const res = await http.delete(`${BASE_URL}/form-response/`, data);

  return assert(res, res.data, 'delete form response failed', res);
};

const getFormResponseDetailById = async (id) => {
  const res = await http.get(`${BASE_URL}/form-response/${id}/`);
  return assert(res, res.data, 'Retrieval of form response detail by its ID failed', res);
};

const getFormResponseListById = async (params) => {
  const queryParams = new URLSearchParams(params).toString();

  const res = await http.get(`${BASE_URL}/form-response-filter/?${queryParams}`);
  return assert(res, res.data, 'Retrieval of form response list by its form ID failed', res);
};

const createFormResponseById = async ({ formId, ...form }) => {
  const res = await http.post(`${BASE_URL}/form-response/${formId}/`, {
    ...form,
    form_id: formId,
  });
  return assert(res, res.data, 'create for form response failed', res);
};

const updateFormResponseById = async ({ formResponseId, formId, ...form }) => {
  const res = await http.put(`${BASE_URL}/form-response/${formResponseId}/`, {
    ...form,
    form_id: formId,
  });
  return assert(res, res.data, 'update for form response failed', res);
};

const addApprovers = async ({ formResponseId, approvers }) => {
  const res = await http.patch(`${BASE_URL}/form-response/add-approvers/${formResponseId}/`, {
    approvers,
  });
  return assert(res, res.data, 'Add approvers failed', res);
};

const getApprovers = async () => {
  const res = await http.get(`${BASE_URL}/forms-requests/approvers/`);
  return assert(res, res.data, 'get approvers failed', res);
};

const getActivityLog = async (params) => {
  const queryParams = new URLSearchParams(params).toString();

  const res = await http.get(`${BASE_URL}/forms/activity-log/?${queryParams}`);
  return assert(res, res.data, 'get activity log failed', res);
};

const approveRejectForm = async (body) => {
  const res = await http.post(`${BASE_URL}/forms/approve-reject/`, body);
  return assert(res, res.data, 'approval form failed', res);
};

const getFormSurveyActivities = async (formId) => {
  const res = await http.get(`${BASE_URL}/forms/${formId}/survey-activities/`);
  return assert(res, res.data, 'get survey activities failed', res);
};

const getSurveySummary = async (formId) => {
  const res = await http.get(`${BASE_URL}/forms/survey-summary/${formId}/`);
  return assert(res, res.data, 'get survey summary failed', res);
};

const deleteFormResponseById = async (formResponseId) => {
  const res = await http.delete(`${BASE_URL}/form-response/delete/${formResponseId}/`);
  return assert(res, res.data, 'delete form response by id failed', res);
};

const bulkDeleteFormResponseByIds = async ({ formResponseIds }) => {
  const params = formResponseIds.join(',');
  const res = await http.delete(`${BASE_URL}/form-response/bulk-delete?form_response_id=${params}`);

  return assert(res, res.data, 'Bulk Delete of form response failed', res);
};

const toggleAcceptingResponse = async ({ formId, status }) => {
  const res = await http.patch(`${BASE_URL}/form-open-close/${formId}/`, { status });

  return assert(res, res.data, 'toggle form response failed', res);
};

const cancelFormResponse = async (data) => {
  const res = await http.post(`${BASE_URL}/form-responses/cancel/`, data);

  return assert(res, res.data, 'cancel form response failed', res);
};

const closeFormResponse = async (data) => {
  const res = await http.post(`${BASE_URL}/form-responses/close/`, data);

  return assert(res, res.data, 'close form response failed', res);
};

const extendFormResponse = async (data) => {
  const res = await http.post(`${BASE_URL}/form-responses/extend/`, data);

  return assert(res, res.data, 'extend form response failed', res);
};

const getFormDetailByIdWithoutAuth = async (formId) => {
  const res = await http.get(`${BASE_URL}/form-request-detail/${formId}/`);
  return assert(res, res.data, 'Retrieval of form detail by its ID failed', res);
};

const uploadImageForm = async (body) => {
  const res = await http.post(`${BASE_URL}/form-response-upload/`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return assert(res, res.data, 'upload image form failed', res);
};

const getFormResponsePdfById = async (formResponseId) => {
  const res = await http.get(`${BASE_URL}/form-response-fetch-pdf/${formResponseId}/`);
  return assert(res, res.data, 'Retrieval of pdf form response failed', res);
};

const getFormPdfById = async (formId) => {
  const res = await http.get(`${BASE_URL}/form-fetch-pdf/${formId}/`);
  return assert(res, res.data, 'Retrieval of pdf form failed', res);
};

const FormService = {
  createForm,
  getFormList,
  getFormTotal,
  getFormCategories,
  getFormDetailById,
  updateFormById,
  deleteFormById,
  duplicateFormById,
  bulkDeleteFormByIds,
  publishFormById,
  validateForm,
  getFormResponseList,
  createFormResponse,
  updateFormResponse,
  deleteFormResponse,
  getFormResponseDetailById,
  createFormResponseById,
  addApprovers,
  getApprovers,
  getActivityLog,
  approveRejectForm,
  getFormSurveyActivities,
  getSurveySummary,
  deleteFormResponseById,
  bulkDeleteFormResponseByIds,
  getFormResponseListById,
  toggleAcceptingResponse,
  cancelFormResponse,
  closeFormResponse,
  extendFormResponse,
  getFormDetailByIdWithoutAuth,
  updateFormResponseById,
  uploadImageForm,
  getFormResponsePdfById,
  getFormPdfById,
};

export default FormService;
