/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  getFormDetailById,
  getFormResponseDetailById,
  getFormResponsePdfById,
} from '../../redux/features/forms/formSlice';
import FormDetailResponsesTab from './components/FormDetail/FormDetailResponsesTab';
import FormResponseDetailActivityTabContent from './components/FormResponseDetail/FormResponseDetailActivityTabContent';
import FormResponseDetailApprovalTabContent from './components/FormResponseDetail/FormResponseDetailApprovalTabContent';
import FormResponseDetailApproverTabContent from './components/FormResponseDetail/FormResponseDetailApproverTabContent';
import FormResponseDetailFormDetailTabContent from './components/FormResponseDetail/FormResponseDetailFormDetailTabContent';
import FormResponseDetailHeader from './components/FormResponseDetail/FormResponseDetailHeader';
import { FormResponseStatus } from './config/forms/constant';

export default function FormResponseDetail() {
  const [selectedTab, setSelectedTab] = useState('formDetails');

  const { id, responseId } = useParams();

  const dispatch = useDispatch();
  const { formResponseDetail } = useSelector((state) => state.form);

  useEffect(() => {
    if (id) {
      dispatch(getFormDetailById(id));
    }

    if (responseId) {
      dispatch(getFormResponseDetailById(responseId));
    }
  }, [responseId, id]);

  const generatePdf = async () => {
    try {
      const result = await dispatch(getFormResponsePdfById(responseId));
      unwrapResult(result);

      // Open the PDF in a new tab
      window.open(result.payload.document_url, '_blank');
    } catch (error) {
      toast.error('Error downloading form');
    }
  };

  const status = formResponseDetail?.response_status;

  return (
    <div className="max-w-screen-2xl mx-auto">
      <div className="my-4 bg-white px-4 pb-4">
        <FormResponseDetailHeader
          onClickPrint={generatePdf}
          showPrint
          disabledPrint={
            status !== FormResponseStatus.Closed.toLowerCase() &&
            status !== FormResponseStatus.Cancelled.toLowerCase() &&
            status !== 'canceled' &&
            status !== FormResponseStatus.Approved.toLowerCase()
          }
        />
        <FormDetailResponsesTab setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
        {selectedTab === 'formDetails' && <FormResponseDetailFormDetailTabContent />}
        {selectedTab === 'approver' && <FormResponseDetailApproverTabContent />}
        {selectedTab === 'approval' && <FormResponseDetailApprovalTabContent />}
        {selectedTab === 'activities' && <FormResponseDetailActivityTabContent />}
      </div>
    </div>
  );
}
