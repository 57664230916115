/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CopyIcon from '../../../../assets/svg-icons/CopyIcon';
import EyeIcon from '../../../../assets/svg-icons/EyeIcon';
import ShareIcon from '../../../../assets/svg-icons/ShareIcon';
import TrashIcon from '../../../../assets/svg-icons/TrashIcon';
import { Badge } from '../../../../components/badge/badge';
import Button from '../../../../components/button/button';
import TextInput from '../../../../components/input/textInput';
import {
  setSelectedFormId,
  setSelectedFormResponseId,
} from '../../../../redux/features/forms/formSlice';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { FormResponseStatus, FormStatus } from '../forms/constant';
import { Role } from '../../../../constants/role';
import { Tooltip, TooltipTrigger } from '../../../../components/tooltip/tooltipV2';
import TooltipIcon from '../../../../assets/svg-icons/TooltipIcon';

export const listFormColumns = ({
  setIsDeleteFormModalOpen,
  setIsShareFormModalOpen,
  setIsDuplicateFormModalOpen,
}) => {
  const { user } = useSelector((state) => state.user);

  return [
    { id: 'selection' },
    {
      Header: 'Form Name',
      accessor: 'title',
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ row }) => {
        return row.original?.category ? row.original.category.name : '-';
      },
    },
    {
      Header: 'Form Number',
      accessor: 'form_number',
      Cell: ({ row }) => {
        if (row.original?.document_reference) {
          return row.original.document_reference;
        } else if (row.original?.form_number) {
          return row.original.form_number;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Form Type',
      accessor: 'form_type',
      Cell: ({ row }) => {
        return row.original?.form_type ? capitalizeFirstLetter(row.original.form_type) : '-';
      },
    },
    {
      Header: 'Created On',
      accessor: 'created_at',
      Cell: ({ row }) => {
        return row.original?.created_at
          ? format(new Date(row.original.created_at), 'MMMM d, yyyy hh:mm aa')
          : '-';
      },
    },

    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => {
        if (!row.original.status) {
          return '-';
        }
        return (
          <Badge
            className="min-w-[67px]"
            variant={row.original.status === FormStatus.Draft ? 'secondary' : 'default'}
          >
            {capitalizeFirstLetter(row.original.status)}
          </Badge>
        );
      },
    },
    {
      Header: 'Published By',
      accessor: 'publishedBy',
      Cell: ({ row }) => {
        return row.original?.published_by?.fullname ? row.original.published_by?.fullname : '-';
      },
    },
    {
      Header: 'Published On',
      accessor: 'published_on',
      Cell: ({ row }) => {
        return row.original?.published_on
          ? format(new Date(row.original.published_on), 'MMMM d, yyyy hh:mm aa')
          : '-';
      },
    },
    {
      Header: 'New responses',
      accessor: 'response_count',
      Cell: ({ row }) => {
        return row.original?.response_count ? (
          <div className="flex items-center gap-1">
            <div className="flex-shrink-0 w-2 h-2 rounded-full bg-theme-color-primary-light" />

            <p className="mb-0 text-neutral-color-darker text-sm">
              {row.original?.response_count} New
            </p>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => {
        const dispatch = useDispatch();

        const onDelete = (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setSelectedFormId(row.original.id));
          setIsDeleteFormModalOpen(true);
        };

        const onUpdate = (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setSelectedFormId(row.original.id));
          setIsShareFormModalOpen(true);
        };

        const onDuplicate = (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setSelectedFormId(row.original.id));
          setIsDuplicateFormModalOpen(true);
        };

        const onPreview = (e) => {
          e.stopPropagation();
          const { sections } = row.original || {};

          const previewItem = sections.map((section) => {
            return {
              id: Math.random(),
              title: section.title,
              description: section.description,
              items: section.fields?.map((item) => {
                return {
                  id: Math.random(),
                  question: item.label,
                  answer: item.field_type,
                  content: item.options
                    ? JSON.parse(item.options).map((option) => {
                        return {
                          id: option.id,
                          text: option.text,
                          type: option.type || undefined,
                        };
                      })
                    : undefined,
                  isRequired: item.is_required,
                };
              }),
            };
          });

          window.sessionStorage.setItem(
            'form',
            JSON.stringify({
              forms: previewItem,
            }),
          );

          window.open(`/forms/preview/${row.original.id}`, '_blank');
        };

        return (
          <div className="flex gap-2 items-center">
            <Button variant="outline" onClick={onDuplicate}>
              <CopyIcon className="text-neutral-color-darker" />
            </Button>

            <Button variant="outline" onClick={onPreview}>
              <EyeIcon className="text-neutral-color-darker" />
            </Button>

            <Button variant="outline" onClick={onDelete} disabled={user.role !== Role.Admin}>
              <TrashIcon className="text-neutral-color-darker" />
            </Button>

            <Button
              variant="outline"
              onClick={onUpdate}
              disabled={row.original.status === FormStatus.Draft}
            >
              <ShareIcon className="text-neutral-color-darker" />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const invitedListColumns = () => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: () => {
        return <TextInput placeholder="Your Answer" variant="ghost" />;
      },
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      Cell: () => {
        return <TextInput placeholder="Your Answer" variant="ghost" />;
      },
    },
  ];
};

export const listResponsesColumns = ({ setIsDeleteFormModalOpen, setIsTakeActionDrawerOpen }) => {
  const { user } = useSelector((state) => state.user);

  return [
    { id: 'selection' },
    {
      Header: 'Form ID',
      accessor: 'form_response_number',
    },
    {
      Header: 'Approver',
      accessor: 'approvers',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original?.approvers?.length > 0
              ? row.original.approvers?.map((item) => {
                  return <p className="mb-0">{item.fullname}</p>;
                })
              : '-'}
          </div>
        );
      },
    },
    {
      Header: 'Requester',
      accessor: 'requester',
      Cell: ({ row }) => {
        return <div>{row.original?.submitted_by?.fullname ?? '-'}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => {
        const getStatusVariant = () => {
          switch (row.original.response_status.toLowerCase()) {
            case FormResponseStatus.NewRequest.toLowerCase():
              return 'default';
            case FormResponseStatus.AwaitingApproval.toLowerCase():
              return 'warning';
            case FormResponseStatus.Approved.toLowerCase():
              return 'success';
            case FormResponseStatus.Cancelled.toLowerCase():
            case 'canceled':
              return 'danger';
            case FormResponseStatus.Closed.toLowerCase():
            case FormResponseStatus.Draft.toLowerCase():
              return 'secondary';
            default:
              return 'default';
          }
        };
        return (
          <Badge variant={getStatusVariant()}>
            {capitalizeFirstLetter(row.original.response_status)}
          </Badge>
        );
      },
    },
    {
      Header: 'Sent On',
      accessor: 'submitted_at',
      Cell: ({ row }) => {
        return row.original?.submitted_at
          ? format(new Date(row.original.submitted_at), 'MMM, d yyyy')
          : '-';
      },
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      Cell: ({ row }) => {
        const showActions =
          row.original.due_date &&
          row.original.response_status === FormResponseStatus.Approved.toLowerCase();
        const dispatch = useDispatch();

        const onTakeAction = (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setSelectedFormResponseId(row.original.form_response_id));
          setIsTakeActionDrawerOpen(true);
        };

        const isPastDueDate = row.original.due_date < 0;

        return (
          <div>
            <div className="flex items-center">
              {row.original.extension_date
                ? format(new Date(row.original.extension_date), 'MMM, d yyyy')
                : row.original?.due_date
                ? format(new Date(row.original.end_time), 'MMM, d yyyy')
                : '-'}
              {showActions && !isPastDueDate && (
                <Tooltip
                  className="min-w-[93px]"
                  content={`Due in ${Math.abs(row.original.due_date)} days`}
                  side="top"
                >
                  <TooltipTrigger className="ml-2">
                    <TooltipIcon />
                  </TooltipTrigger>
                </Tooltip>
              )}
            </div>

            <div>
              {showActions && !isPastDueDate && (
                <Button
                  variant="outline"
                  className="text-theme-color-primary-main text-xs"
                  onClick={onTakeAction}
                >
                  Take actions
                </Button>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => {
        const dispatch = useDispatch();
        const onDelete = (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setSelectedFormResponseId(row.original.form_response_id));
          setIsDeleteFormModalOpen(true);
        };

        return (
          <div className="flex gap-2 items-center">
            <Button variant="outline" onClick={onDelete} disabled={user.role !== Role.Admin}>
              <TrashIcon className="text-neutral-color-darker" />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const listSurveyResponsesColumns = ({ setIsDeleteFormModalOpen }) => {
  const { user } = useSelector((state) => state.user);

  return [
    { id: 'selection' },
    {
      Header: 'Form ID',
      accessor: 'form_response_number',
    },
    {
      Header: 'Respondent',
      accessor: 'respondent_email',
    },
    {
      Header: 'Sent On',
      accessor: 'sentOn',
      Cell: ({ row }) => {
        return row.original?.submitted_at
          ? format(new Date(row.original.submitted_at), 'MMM, d yyyy')
          : '-';
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => {
        const dispatch = useDispatch();

        const onDelete = (e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setSelectedFormResponseId(row.original.form_response_id));
          setIsDeleteFormModalOpen(true);
        };

        return (
          <div className="flex gap-2 items-center">
            <Button variant="outline" onClick={onDelete} disabled={user.role !== Role.Admin}>
              <TrashIcon className="text-neutral-color-darker" />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const listSurveySummaryColumns = () => {
  return [
    {
      Header: 'No',
      accessor: 'no',

      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
  ];
};

export const listSurveySummaryLongTextColumns = () => {
  return [
    {
      Header: 'No',
      accessor: 'no',

      Cell: ({ row }) => {
        return <div>{row.index + 1}</div>;
      },
    },
    {
      Header: 'Answer',
      accessor: 'answer',
    },
  ];
};
