/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CopyIcon from '../../../../assets/svg-icons/CopyIcon';
import EllipsisIcon from '../../../../assets/svg-icons/EllipsisIcon';
import PrintIcon from '../../../../assets/svg-icons/PrintIcon';
import QRIcon from '../../../../assets/svg-icons/QRIcon';
import SendIcon from '../../../../assets/svg-icons/SendIcon';
import TrashIcon from '../../../../assets/svg-icons/TrashIcon';
import Button from '../../../../components/button/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/popover/popover';
import {
  deleteFormById,
  duplicateFormById,
  getFormPdfById,
} from '../../../../redux/features/forms/formSlice';
import { cn } from '../../../../utils/utilClassNames';
import { FormStatus } from '../../config/forms/constant';
import DeleteFormModal from '../Modal/DeleteFormModal';
import DuplicateFormModal from '../Modal/DuplicateFormModal';

export default function FormConfigurationAction({ onClickPublish }) {
  const [isDuplicateFormModalOpen, setIsDuplicateFormModalOpen] = React.useState(false);
  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = React.useState(false);

  const { id } = useParams();
  const formContext = useFormContext();

  const { form, isFormDuplicateLoading, isFormDeleteLoading } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePrint = async () => {
    try {
      const result = await dispatch(getFormPdfById(id));
      unwrapResult(result);

      // Open the PDF in a new tab
      window.open(result.payload.document_url, '_blank');
    } catch (error) {
      toast.error('Error downloading form');
    }
  };

  const handleQrCode = () => {
    window.open(`/forms/submit-form/${id}/qr-code`, '_blank');
  };

  const onDuplicateFormById = async () => {
    try {
      const result = await dispatch(duplicateFormById(id));
      unwrapResult(result);
      toast.success('Form duplicated successfully');
      history.push('/forms');
      setIsDuplicateFormModalOpen(false);
    } catch (error) {
      toast.error('Error duplicating form');
    }
  };

  const onDeleteFormById = async () => {
    try {
      const result = await dispatch(deleteFormById(id));
      unwrapResult(result);
      toast.success('Form deleted successfully');
      history.push('/forms');

      setIsDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting form');
    }
  };

  return (
    <div>
      <p className="text-neutral-color-darker text-sm">Action</p>

      <div className="flex items-center gap-2">
        <Button
          variant="tersiary"
          className="h-[42px] p-0 rounded-[8px] w-[42px] [&>svg]:size-6"
          onClick={onClickPublish}
          disabled={
            !formContext.formState.isValid ||
            form?.status === FormStatus.Published ||
            form?.status === 'closed' ||
            form?.status === 'open'
          }
        >
          <SendIcon className="text-white" />
        </Button>

        <Button
          disabled={form?.status === FormStatus.Draft || form?.status === 'closed'}
          variant="outline"
          onClick={handleQrCode}
          className="h-[42px] p-0 rounded-[8px] w-[42px] [&>svg]:size-6"
        >
          <QRIcon />
        </Button>

        <Popover>
          <PopoverTrigger>
            <Button
              variant="outline"
              className="h-[42px] p-0 rounded-[8px] w-[42px] [&>svg]:size-6"
            >
              <EllipsisIcon className="text-black" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            align="start"
            side="bottom"
            className="w-[158px] py-2 px-3 flex flex-col gap-4"
          >
            <div
              className={cn(
                'flex items-center gap-4 cursor-pointer',
                form.status === FormStatus.Draft ? 'cursor-not-allowed' : '',
              )}
              onClick={form.status === FormStatus.Draft ? () => {} : handlePrint}
            >
              <PrintIcon
                className={cn(
                  form.status === FormStatus.Draft
                    ? 'text-neutral-color-main'
                    : 'text-theme-color-secondary-main',
                )}
              />
              <p className="text-neutral-color-main mb-0 text-sm">Print Form</p>
            </div>

            <div
              className="flex items-center gap-4 cursor-pointer"
              onClick={() => setIsDuplicateFormModalOpen(true)}
            >
              <CopyIcon className="text-theme-color-secondary-main" />
              <p className="text-neutral-color-main mb-0 text-sm">Duplicate Form</p>
            </div>

            <div
              className="flex items-center gap-4 cursor-pointer"
              onClick={() => setIsDeleteFormModalOpen(true)}
            >
              <TrashIcon className="text-theme-color-secondary-main" />
              <p className="text-neutral-color-main mb-0 text-sm">Delete Form</p>
            </div>
          </PopoverContent>
        </Popover>
      </div>

      <DeleteFormModal
        open={isDeleteFormModalOpen}
        onClose={() => setIsDeleteFormModalOpen(false)}
        onDelete={onDeleteFormById}
        isDeleteLoading={isFormDeleteLoading}
      />

      <DuplicateFormModal
        open={isDuplicateFormModalOpen}
        onClose={() => setIsDuplicateFormModalOpen(false)}
        onDuplicate={onDuplicateFormById}
        isDuplicateLoading={isFormDuplicateLoading}
      />
    </div>
  );
}

FormConfigurationAction.propTypes = {
  onClickPublish: PropTypes.func.isRequired,
};
