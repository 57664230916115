import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import Button from '../../../../components/button/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import SignatureInput from '../../../../components/input/signatureInput';
import { requestApprovedFormSchema } from '../../config/forms/validation';
import SaveApprovalModal from '../Modal/SaveApprovalModal';
import {
  approveRejectForm,
  getFormResponseDetailById,
} from '../../../../redux/features/forms/formSlice';

export default function FormDetailResponsesApproved() {
  const [isApproveApprovalModalOpen, setIsApproveApprovalModalOpen] = useState(false);

  const { responseId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(requestApprovedFormSchema),
  });

  const { isFormApproveRejectLoading, formResponseDetail } = useSelector((state) => state.form);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const inputRef = useRef();

  const onApprove = async () => {
    try {
      const result = await dispatch(
        approveRejectForm({
          form_response_id: Number(responseId),
          decision: 'approve',
          approver_signatures: [
            {
              approver_id: user.id,
              signature: form.watch('signature'),
            },
          ],
        }),
      );
      unwrapResult(result);
      inputRef.current.clear();
      dispatch(getFormResponseDetailById(responseId));

      setIsApproveApprovalModalOpen(false);
      toast.success('Form response approved successfully');
    } catch (error) {
      toast.error('Error approving form response');
    }
  };

  const savedSignature = formResponseDetail?.approvers_signatures?.find(
    (signature) => signature.approver_id === user.id,
  )?.signature;

  return (
    <Form {...form}>
      <form className="space-y-4">
        <div className="border border-neutral-color-divider py-3 px-4 rounded-lg bg-neutral-variant-color-lighter">
          <h2 className="mb-0 font-semibold text-neutral-color-darker text-sm">Request approved</h2>
        </div>

        <div>
          <FormField
            control={form.control}
            name="signature"
            render={({ field }) => (
              <FormItem>
                <FormLabel isRequired>Signature</FormLabel>
                <FormControl>
                  <SignatureInput
                    inputRef={inputRef}
                    height={300}
                    onChange={field.onChange}
                    defaultValue={savedSignature}
                    disabled={savedSignature}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex justify-end">
          <Button
            disabled={!form.formState.isValid || !!savedSignature}
            className="w-[83px] h-[42px] rounded-[8px]"
            onClick={() => setIsApproveApprovalModalOpen(true)}
          >
            Save
          </Button>
        </div>

        <SaveApprovalModal
          open={isApproveApprovalModalOpen}
          onClose={() => setIsApproveApprovalModalOpen(false)}
          onApprove={onApprove}
          isLoading={isFormApproveRejectLoading}
        />
      </form>
    </Form>
  );
}
