/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import IconVerticalBox from '../../../../assets/svg-icons/IconVerticalBox';
import ResetIcon from '../../../../assets/svg-icons/ResetIcon';
import Rotate360Icon from '../../../../assets/svg-icons/Rotate360Icon';
import TrashIcon from '../../../../assets/svg-icons/TrashIcon';
import classes from '../../../../components/assetsTable/assetsTable.module.css';
import Button from '../../../../components/button/button';
import Label from '../../../../components/label/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../components/select/selectV2';
import Switch from '../../../../components/switch/switch';
import { Tooltip, TooltipTrigger } from '../../../../components/tooltip/tooltipV2';
import {
  getFormDetailById,
  getFormResponseListById,
  resetBasicResponsesFilter,
  setFilterApproverResponse,
  setFilterStatusResponse,
  toggleAcceptingResponse,
} from '../../../../redux/features/forms/formSlice';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { FormResponseStatus } from '../../config/forms/constant';
import FormRequestResponseAdvancedFilterModal from '../Modal/FormRequestResponseAdvanceFilterModal';

export default function FormDetailResponsesFilter({
  allColumns,
  setSelectedFilter,
  selectedFilter,
  onOpenBulkDelete,
  selectedForms,
  getFormData,
}) {
  const { id } = useParams();

  const statusOption = Object.values(FormResponseStatus).map((status) => ({
    label: status,
    value: status.toLowerCase(),
  }));

  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = React.useState(false);
  const [isColumnFilterOpen, setIsColumnFilterOpen] = React.useState(false);

  const { filterApproverResponse, filterStatusResponse, approvers, form } = useSelector(
    (state) => state.form,
  );
  const dispatch = useDispatch();

  const onReset = () => {
    dispatch(resetBasicResponsesFilter());

    dispatch(getFormResponseListById({ form_id: id }));
  };

  const onReload = () => {
    getFormData();
  };

  const approverOptions = approvers?.map((approver) => ({
    name: approver.fullname || approver.email,
    value: approver.id,
  }));

  const isAcceptingResponse = form?.status !== 'closed';

  const handleToggleResponse = async () => {
    try {
      const result = await dispatch(
        toggleAcceptingResponse({ formId: id, status: isAcceptingResponse ? 'closed' : 'open' }),
      );
      unwrapResult(result);
      toast.success('Toggle form accepting successfully');
      dispatch(getFormDetailById(id));
    } catch (error) {
      toast.error('Error toggling form accepting');
    }
  };

  return (
    <div className="justify-between flex items-end gap-4">
      <div className="flex space-between items-end w-full max-w-[550px] gap-4">
        <div className="w-full">
          <Label className="font-semibold">Approver</Label>
          <Select
            value={filterApproverResponse}
            onValueChange={(value) => {
              dispatch(setFilterApproverResponse(value));
            }}
          >
            <SelectTrigger className="!bg-[#ffffff]">
              <SelectValue placeholder="All Approvers" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {approverOptions?.map((option) => {
                  return (
                    <SelectItem value={option.value} key={option.value} className="px-3">
                      {option.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <Label className="font-semibold">Status</Label>
          <Select
            value={filterStatusResponse}
            onValueChange={(value) => {
              dispatch(setFilterStatusResponse(value));
            }}
          >
            <SelectTrigger className="!bg-[#ffffff]">
              <SelectValue placeholder="All status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {statusOption?.map((option) => {
                  return (
                    <SelectItem value={option.value} key={option.value} className="px-3">
                      {capitalizeFirstLetter(option.label)}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Button variant="outline" className="h-[42px] w-[94px] rounded-lg" onClick={onReset}>
          <ResetIcon />
          Reset
        </Button>

        <Button variant="outline" className="h-[42px] w-[94px] rounded-lg" onClick={onReload}>
          <Rotate360Icon />
          Reload
        </Button>
      </div>

      <div className="flex gap-3 items-center">
        <div className="flex items-center gap-2">
          <Switch checked={isAcceptingResponse} onChange={handleToggleResponse} />
          <Label>Accepting Responses</Label>
        </div>

        <Button
          variant="primary"
          className="h-[42px] w-[123px] rounded-lg font-semibold"
          onClick={() => setIsAdvancedFilterOpen(true)}
        >
          Advance filter
        </Button>

        <div className="relative">
          <Button
            variant="outline"
            className="size-[42px] p-0"
            onClick={() => setIsColumnFilterOpen(true)}
          >
            <IconVerticalBox className="text-neutral-color-darker flex-shrink-0" />
          </Button>

          {isColumnFilterOpen && (
            <>
              <div
                className="tck-overlay"
                onClick={() => setIsColumnFilterOpen(!isColumnFilterOpen)}
              />
              <div className={classes.newFilterTable}>
                <div className={classes.newFilterTableCheckboxs}>
                  <p className="weekly-frequency-label ml-0">Filter column</p>
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map((column) => {
                    if (column.id !== 'selection') {
                      return (
                        <div key={column.id} className={classes.newFilterTableCheckboxs}>
                          <Switch
                            name={column.accessor}
                            checked={selectedFilter.includes(column.accessor)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedFilter([...selectedFilter, e.target.name]);
                              } else {
                                setSelectedFilter(
                                  selectedFilter.filter((item) => item !== e.target.name),
                                );
                              }
                            }}
                          />
                          <span className="weekly-frequency-label">{column.Header}</span>
                        </div>
                      );
                    }
                  })}
                  <br />
                </div>
              </div>
            </>
          )}
        </div>

        <Tooltip content="Bulk Delete" className="w-[83px]" side="top">
          <TooltipTrigger>
            <Button
              variant="outline"
              className="size-[42px] p-0"
              disabled={selectedForms?.length === 0}
              onClick={onOpenBulkDelete}
            >
              <TrashIcon className="text-neutral-color-darker flex-shrink-0" />
            </Button>
          </TooltipTrigger>
        </Tooltip>
      </div>

      <FormRequestResponseAdvancedFilterModal
        open={isAdvancedFilterOpen}
        handleClose={() => {
          setIsAdvancedFilterOpen(false);
        }}
        getFormData={getFormData}
      />
    </div>
  );
}
