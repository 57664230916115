import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '../../utils/utilClassNames';

export default function Switch({ onChange, checked, name, disabled }) {
  return (
    <label className="weekly-frequency-switch black-switch m-0">
      <input
        id="checkbox-toggle"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        name={name}
        disabled={disabled}
      />
      <span
        className={cn(
          'weekly-frequency-slider',
          disabled ? '!bg-neutral-color-main !cursor-not-allowed' : '',
        )}
      />
    </label>
  );
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
